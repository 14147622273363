/* Navbar.css */

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Setzt die Breite auf die gesamte Bildschirmbreite */
    padding: 10px 50px; 
    background-color: #32343a;
    position: relative;
    z-index: 1000;
  }
  .nav a img {
    height: 80px; /* Höhe des Logos */
  }  
  
.nav__list{
    align-items: center;
}
  .nav .nav__list .nav__link {
    font-family: Varelaround, sans-serif;
    color: #ffffff;
    font-weight: 500;
    font-size: 20px;
    text-decoration: none;
    text-transform: uppercase;
  }

  .nav__toggle,
  .nav__close {
   font-size: 1.5rem;
    display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #FFFFFF; /* Weißer Hintergrund */
  border: none;
  cursor: pointer;
  font-size: 24px; /* Größe des Emoji-Icons */
  color: #000000; /* Schwarze Icons */
  }
  
  .nav__cta {
    display: flex; /* Aktiviert Flexbox */
    flex-direction: column; /* Stapelt die Kinder vertikal */
    justify-content: center; /* Zentriert die Kinder vertikal */
    align-items: center; /* Zentriert die Kinder horizontal */
    min-height: 70px;
    min-width: 150px;
    background-color: #fff;
    border-radius: 40px;
    font-weight: 600;
    text-align: center;
    padding: 0px 20px;
    text-decoration: none; /* 10px vertikaler und 30px horizontaler Abstand */
}
  
.nav__cta-link{
    text-decoration: none;
}

  @media screen and (max-width: 1390px) {
    .nav__menu {
      position: fixed;
      top: 0;
      right: -100%;
      background-color: hsla(0, 0%, 6%, 0.2);
      backdrop-filter: blur(16px);
      -webkit-backdrop-filter: blur(16px);
      width: 80%;
      height: 100%;
      padding: 6rem 3rem 0;
      transition: right 0.4s;
      z-index: 1000;
    }
  }
  @media screen and (max-width: 300px) {
    .nav__menu {
      padding: 4rem 1rem 2rem;
    }
  
    .nav__list {
      row-gap: 1rem;
    }
  
    .nav__link,
    .nav__cta {
      font-size: var(--smaller-font-size);
    }
  
    .nav__cta {
      padding: 8px 16px;
      font-size: 12px;
      margin-top: 1rem;
      width: auto;
      white-space: nowrap;
    }
  }
  
  .nav__list {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
  }
  
  .nav__link {
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
    transition: color 0.4s;
  }
  
  .nav__link:hover {
    color: var(--first-color);
  }
  
  .nav__close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
  }
  
  .show-menu {
    right: 0;
  }
  .nav__listt {
    display: flex;
    flex-direction: row;
    column-gap: 2.5rem;
  }
  
  .nav__menu_mobile {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: hsla(0, 0%, 6%, 0.2);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    width: 80%;
    height: 100%;
    padding: 6rem 3rem 0;
    transition: right 0.4s;
  }
  @media screen and (min-width: 1280px) {
    .nav {
      height: calc(var(--header-height) + 1rem);
    }
    .nav__toggle,
    .nav__close {
      display: none;
    }
  
    .nav__list {
      flex-direction: row;
      column-gap: 2.5rem;
    }
  }







  
