.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-layout-layout {
  display: flex;
  width: 100%;
  padding: 30px;
  gap: 20px;
}

.div-block102{
  padding-right: 20px;
  gap: 30px;
}

.w-layout-cell {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

body {
  color: #1a1b1f;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 44px;
  font-weight: 400;
  line-height: 62px;
}

h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 400;
  line-height: 50px;
}

h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 400;
  line-height: 46px;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

p {
  margin-bottom: 10px;
}

a {
  color: #1a1b1f;
  text-decoration: underline;
  transition: opacity .2s;
  display: block;
}

a:hover {
  color: #32343a;
}

a:active {
  color: #43464d;
}

ul {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 40px;
  list-style-type: disc;
}

li {
  margin-bottom: 10px;
}

img {
  display: block;
}

label {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

blockquote {
  border-left: 5px solid #e2e2e2;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 15px 30px;
  font-size: 20px;
  line-height: 34px;
}

figcaption {
  opacity: 1;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  line-height: 26px;
}

.divider {
  height: 1px;
  background-color: #eee;
}

.styleguide-content-wrap {
  text-align: center;
}

.section {
  margin-left: 30px;
  margin-right: 30px;
}

.section.cc-cta {
  background-color: #f4f4f4;
  padding-left: 80px;
  padding-right: 80px;
}

.styleguide-block {
  text-align: left;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 80px;
  display: block;
}

.container {
  width: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}

.paragraph-tiny {
  font-size: 12px;
  line-height: 20px;
}

.paragraph-bigger {
  opacity: 1;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
}

.paragraph-bigger.cc-bigger-light, .paragraph-bigger.cc-bigger-white-light {
  opacity: .6;
}

.button {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #1a1b1f;
  border-radius: 0;
  padding: 12px 25px;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
  transition: background-color .4s, opacity .4s, color .4s;
}

.button:hover {
  color: #fff;
  background-color: #32343a;
}

.button:active {
  background-color: #43464d;
}

.button.cc-jumbo-button {
  padding: 16px 35px;
  font-size: 14px;
  line-height: 26px;
}

.button.cc-jumbo-button.cc-jumbo-white {
  color: #000;
  background-color: #fff;
}

.button.cc-jumbo-button.cc-jumbo-white:hover {
  background-color: #f1f1f1;
}

.button.cc-jumbo-button.cc-jumbo-white:active {
  background-color: #e1e1e1;
}

.button.cc-contact-us {
  z-index: 5;
  position: relative;
}

.button.cc-white-button {
  color: #202020;
  background-color: #fff;
  padding: 16px 35px;
  font-size: 14px;
  line-height: 26px;
}

.button.cc-white-button:hover {
  background-color: rgba(255, 255, 255, .8);
}

.button.cc-white-button:active {
  background-color: rgba(255, 255, 255, .9);
}




.label {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.label.cc-styleguide-label {
  margin-bottom: 25px;
}

.label.cc-light {
  opacity: .6;
}

.label.cc-blog-date {
  opacity: .6;
  margin-top: 20px;
}

.heading-jumbo-tiny {
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
}

.rich-text {
  width: 70%;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
}

.rich-text p {
  opacity: .6;
  margin-top: 15px;
  margin-bottom: 25px;
}

.rich-text figcaption {
  opacity: .6;
}

.rich-text figure {
  margin-top: 25px;
  padding-bottom: 20px;
}

.paragraph-light {
  opacity: .6;
}

.heading-jumbo {
  text-transform: none;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 64px;
  line-height: 80px;
}

.heading-jumbo-small {
  text-transform: none;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 36px;
  font-weight: 400;
  line-height: 50px;
}

.styleguide-button-wrap {
  margin-top: 10px;
  margin-bottom: 10px;
}

.styleguide-header-wrap {
  height: 460px;
  color: #fff;
  text-align: center;
  background-color: #1a1b1f;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  display: flex;
}

.paragraph-small {
  font-size: 14px;
  line-height: 26px;
}

.logo-link {
  z-index: 1;
}

.logo-link:hover {
  opacity: .8;
}

.logo-link:active {
  opacity: .7;
}

.menu {
  z-index: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.navigation-wrap {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navigation {
  background-color: rgba(0, 0, 0, 0);
  align-items: center;
  padding: 30px 50px;
  display: flex;
}

.navigation-item {
  opacity: .6;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.navigation-item:hover {
  opacity: .9;
}

.navigation-item:active {
  opacity: .8;
}

.navigation-item.w--current {
  opacity: 1;
  color: #1a1b1f;
  font-weight: 600;
}

.navigation-item.w--current:hover {
  opacity: .8;
  color: #32343a;
}

.navigation-item.w--current:active {
  opacity: .7;
  color: #32343a;
}

.logo-image {
  display: block;
}

.navigation-items {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.footer-wrap {
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
}

.webflow-link {
  opacity: .5;
  align-items: center;
  text-decoration: none;
  transition: opacity .4s;
  display: flex;
}

.webflow-link:hover {
  opacity: 1;
}

.webflow-link:active {
  opacity: .8;
}

.webflow-logo-tiny {
  margin-top: -2px;
  margin-right: 8px;
}

.cta-text {
  width: 70%;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
}

.cta-wrap {
  text-align: center;
  justify-content: center;
  padding-top: 110px;
  padding-bottom: 120px;
  display: flex;
}

.intro-header {
  height: 620px;
  color: #fff;
  background-color: #f4f4f4;
  background-position: 0 0;
  background-size: cover;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  display: flex;
}

.intro-header.cc-subpage {
  height: 480px;
  color: #fff;
  background-color: #000;
  background-image: none;
}

.intro-content {
  width: 70%;
  max-width: 1140px;
  text-align: center;
}

.intro-content.cc-homepage {
  margin-bottom: 20px;
}

.motto-wrap {
  width: 80%;
  text-align: center;
  margin-bottom: 85px;
  margin-left: auto;
  margin-right: auto;
}

.about-story-wrap {
  width: 80%;
  text-align: center;
  margin: 80px auto;
}

.our-services-grid {
  grid-column-gap: 80px;
  grid-row-gap: 60px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: ". . ."
                       ". . .";
  margin-bottom: 120px;
}

.section-heading-wrap {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
}

.service-icon {
  margin-bottom: 30px;
}

.status-message {
  color: #fff;
  text-align: center;
  background-color: #202020;
  padding: 9px 30px;
  font-size: 14px;
  line-height: 26px;
}

.status-message.cc-success-message {
  background-color: #12b878;
}

.status-message.cc-error-message {
  background-color: #db4b68;
}

.status-message.cc-no-data {
  width: 70%;
  margin: 100px auto;
  display: block;
}

.contact-form-wrap {
  border: 1px solid #eee;
  padding: 45px 50px 50px;
}

.contact-form-grid {
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template: ". ."
  / 2.5fr 1fr;
  align-items: start;
  margin-top: 100px;
  margin-bottom: 100px;
}

.details-wrap {
  margin-bottom: 30px;
}

.get-in-touch-form {
  flex-direction: column;
  display: flex;
}

.text-field {
  border: 1px solid #e4e4e4;
  border-radius: 0;
  margin-bottom: 18px;
  padding: 21px 20px;
  font-size: 14px;
  line-height: 26px;
  transition: border-color .4s;
}

.text-field:hover {
  border-color: #e3e6eb;
}

.text-field:active, .text-field:focus {
  border-color: #43464d;
}

.text-field::-ms-input-placeholder {
  color: rgba(50, 52, 58, .4);
}

.text-field::placeholder {
  color: rgba(50, 52, 58, .4);
}

.text-field.cc-contact-field {
  margin-bottom: 25px;
}

.text-field.cc-textarea {
  height: 200px;
  padding-top: 12px;
}

.contact-form {
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.contact-form-heading-wrap {
  margin-bottom: 40px;
}

.contact-heading {
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: 400;
}

.map {
  height: 460px;
  margin-bottom: 100px;
  line-height: 20px;
}

.contact-team-name-wrap {
  margin-bottom: 30px;
}

.our-contacts {
  grid-column-gap: 80px;
  grid-row-gap: 60px;
  text-align: center;
  grid-template: ". . ."
  / 1fr 1fr 1fr;
  margin-bottom: 120px;
}

.contact-team-details-wrap {
  margin-top: 30px;
}

.contact-team-pic {
  height: 150px;
  background-color: #f4f4f4;
  margin-bottom: 30px;
}

.team-pic {
  width: 100%;
  height: 420px;
  background-color: #f4f4f4;
  margin-bottom: 40px;
}

.team-members {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: ". . ."
                       ". . .";
  margin-bottom: 60px;
}

.team-member-title-wrap {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  display: flex;
}

.team-member-name {
  opacity: 1;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
}

.projects-grid {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template: "."
                 "."
                 "."
                 / 1fr;
  margin-bottom: 60px;
}

.project-name-wrap {
  width: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  display: flex;
}

.project-name-link {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  text-decoration: none;
}

.project-name-link:hover {
  opacity: .8;
}

.project-name-link:active {
  opacity: .7;
}

.project-cover-link {
  margin-bottom: 40px;
}

.project-cover-link:hover {
  opacity: .8;
}

.project-cover-link:active {
  opacity: .7;
}

.project-overview-header {
  height: 620px;
  color: #fff;
  background-color: #f4f4f4;

  background-position: 50%;
  background-size: cover;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  display: flex;
}

.project-overview-header.cc-project-2-header {

  background-position: 50%;
}

.project-overview-header.cc-project-3-header {

  background-size: cover;
}

.project-details-grid {
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template: ". ."
  / 1fr 2fr;
  align-items: start;
  margin-top: 100px;
  margin-bottom: 100px;
}

.project-pics {
  margin-bottom: 120px;
}

.detail-image {
  width: 100%;
  margin-bottom: 30px;
}

.blog-detail-header-wrap {
  width: 70%;
  margin: 60px auto;
}

.detail-header-image {
  width: 100%;
  height: 620px;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0 0;
  background-size: cover;
  margin-bottom: 60px;
  display: block;
}

.detail-header-image.w--current {
  margin-bottom: 60px;
}

.blog-list-wrap {
  margin-bottom: 100px;
}

.blog-item {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.blog-preview-image {
  width: 100%;
  height: 620px;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0 0;
  background-size: cover;
  margin-bottom: 45px;
  transition: opacity .6s;
  display: block;
}

.blog-preview-image:hover {
  opacity: .8;
}

.blog-preview-image:active {
  opacity: .7;
}

.blog-summary-wrap {
  width: 70%;
  text-align: left;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.blog-heading-link {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 400;
  line-height: 46px;
  text-decoration: none;
}

.blog-heading-link:hover {
  opacity: .8;
}

.blog-heading-link:active {
  opacity: .7;
}

.contact-email-link {
  opacity: .6;
  margin-bottom: 5px;
}

.contact-email-link:hover {
  opacity: 1;
}

.contact-email-link:active {
  opacity: .8;
}

.protected-form {
  flex-direction: column;
  display: flex;
}

.protected-wrap {
  justify-content: center;
  padding-top: 90px;
  padding-bottom: 100px;
  display: flex;
}

.protected-heading {
  margin-bottom: 30px;
}

.utility-page-wrap {
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 30px;
  display: flex;
}

._404-wrap {
  width: 100%;
  height: 100%;
  background-color: #1a1b1f;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  display: flex;
}

._404-content-wrap {
  margin-bottom: 20px;
}

.home-content-wrap {
  margin-top: 80px;
  margin-bottom: 120px;
}

.home-section-wrap {
  margin-bottom: 30px;
}

.section-heading {
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 400;
}

.about-grid {
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template: ". ."
  / 1fr 2fr;
  align-items: center;
  margin-bottom: 80px;
}

.about-grid.cc-about-2 {
  grid-template-columns: 2fr 1fr;
  grid-template-areas: ". .";
}

.intro-text {
  width: 80%;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
}

.collection-wrap {
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.work-heading {
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 400;
}

.blog-heading {
  text-align: center;
  margin-bottom: 60px;
}

.blog-preview-wrap {
  width: 33.33%;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 45px;
}

.collection-list-wrapper {
  margin-bottom: 120px;
}

.business-article-heading {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  text-decoration: none;
}

.business-article-heading:hover {
  opacity: .8;
}

.business-article-heading:active {
  opacity: .7;
}

._w-lightbox-caption {
  background-color: rgba(0, 0, 0, .6);
}

.navbar-no-shadow {
  background-color: #272727;
}

.navbar-no-shadow-container {
  z-index: 5;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.container-regular {
  width: 100%;
  min-height: 30px;
  margin-left: auto;
  margin-right: auto;
}

.navbar-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nav-menu {
  color: #fff;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-left: 0;
  display: flex;
}

.nav-link {
  color: #fff;
  letter-spacing: .25px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  font-family: Inconsolata, monospace;
  font-size: 25px;
  line-height: 20px;
  text-decoration: none;
  transition: none;
  overflow: visible;
}

.nav-link:hover {
  color: rgba(26, 27, 31, .75);
}

.nav-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-dropdown {
  margin-left: 5px;
  margin-right: 5px;
}

.nav-dropdown-toggle {
  letter-spacing: .25px;
  padding: 5px 30px 5px 10px;
  font-size: 14px;
  line-height: 20px;
}

.nav-dropdown-toggle:hover {
  color: rgba(26, 27, 31, .75);
}

.nav-dropdown-toggle:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-toggle[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-icon {
  color: #fff;
  margin-right: 10px;
}

.nav-dropdown-list {
  background-color: #fff;
  border-radius: 12px;
}

.nav-dropdown-list.w--open {
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-dropdown-link {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.nav-dropdown-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-button-wrapper {
  margin-left: 120px;
}

.button-primary {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #1a1b1f;
  padding: 12px 25px;
  font-size: 12px;
  line-height: 20px;
  transition: all .2s;
}

.button-primary:hover {
  color: #fff;
  background-color: #32343a;
}

.button-primary:active {
  background-color: #43464d;
}

.lynx-button-white {
  color: #1f2022;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  background-color: #fff;
  border-radius: 50px;
  padding: 22px 36px;
  font-family: Varela, sans-serif;
  font-size: 13px;
  line-height: 140%;
  text-decoration: none;
  transition: color .2s, background-color .2s;
}

.lynx-button-white:hover {
  color: #fff;
  background-color: #1f2022;
}

.lynx-paragraph-white {
  max-width: 460px;
  color: #fff;
  margin-bottom: 36px;
  font-family: Varelaround, sans-serif;
  font-size: 25px;
  line-height: 165%;
}

.lynx-heading-white {
  max-width: 740px;
  color: #fff;
  margin-top: 0;
  margin-bottom: 36px;
  font-family: Varelaround, sans-serif;
  font-size: 58px;
  font-weight: 400;
  line-height: 114%;
}

.lynx-subtitle-white {
  color: #fff;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  font-family: Varela, sans-serif;
  font-size: 12px;
  line-height: 140%;
}

.lynx-block-hero-large {
  width: 45%;
  background-color: #19c1c8;
  border-radius: 30px 30px 360px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: -34px;
  padding: 40px 40px 40px 43px;
  display: flex;
}

.lynx-hero-large {
  position: relative;
  width: 100%;
  min-height: 650px;
  border-radius: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  box-shadow: 0 60px 60px -15px rgba(31, 32, 34, .1);
}

.lynx-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Stellt sicher, dass das Bild den Container bedeckt */
  z-index: -1; /* Stellt das Bild hinter den Inhalt */
  border-radius: inherit; /* Übernimmt die border-radius-Eigenschaft vom Elternelement */
}

.lynx-content-hero {
  width: 100%;
  max-width: 1392px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.lynx-image {
  height: 500px;
  max-width: 100%;
  object-fit: cover;
  border-radius: 30px;
  box-shadow: 0 60px 60px -15px rgba(31, 32, 34, .1);
}

.lynx-image-absolute {
  z-index: 50;
  max-width: 56%;
  object-fit: cover;
  border-radius: 30px;
  position: absolute;
  top: auto;
  bottom: -6%;
  left: -12%;
  right: auto;
  box-shadow: 0 60px 60px -15px rgba(31, 32, 34, .1);
}

.lynx-block-image {
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  display: flex;
  position: relative;
}

.lynx-button {
  color: #fff;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  background-color: #1f2022;
  border-radius: 50px;
  padding: 22px 36px;
  font-family: Varela, sans-serif;
  font-size: 13px;
  line-height: 140%;
  text-decoration: none;
  transition: background-color .2s;
}

.lynx-button:hover {
  background-color: #8979ec;
}

.lynx-paragraph {
  max-width: 820px;
  color: #797c83;
  margin-bottom: 36px;
  font-family: Varelaround, sans-serif;
  font-size: 30px;
  line-height: 165%;
}

.lynx-heading {
  max-width: 740px;
  color: #1f2022;
  margin-top: 0;
  margin-bottom: 36px;
  font-family: Varelaround, sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 120%;
}

.lynx-subtitle {
  color: #1f2022;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  font-family: Varela, sans-serif;
  font-size: 12px;
  line-height: 140%;
}

.lynx-block-left {
  padding-right: 15%;
}

.lynx-grid-image-right {
  width: 100%;
  grid-column-gap: 26px;
  grid-row-gap: 26px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1.2fr;
  align-items: center;
  margin-top: 30px;
}

.lynx-content {
  width: 100%;
  max-width: 1080px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 74px auto 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.lynx-content-copy {
  width: 100%;
  max-width: 1080px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.section-2 {
  -webkit-text-stroke-color: #19c1c8;
  margin-top: 58px;
}

.section-3 {
  margin-top: 93px;
}

.text-block {
  color: #fff;
  font-family: Varelaround, sans-serif;
  font-size: 25px;
  font-weight: 400;
}

.text-block-2, .text-block-3, .text-block-4, .text-block-5 {
  color: #fff;
  font-family: Varelaround, sans-serif;
  font-size: 25px;
}

.list {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  font-size: 30px;
  font-weight: 500;
}

.text-block-6 {
  color: #fff;
}

.lynx-grid-image-right-copy {
  width: 100%;
  grid-column-gap: 26px;
  grid-row-gap: 26px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1.2fr;
  align-items: center;
  margin-top: 60px;
}

.section-4 {
  height: 200px;
}

.list-item {
  color: #03f;
}

.nav-link-copy {
  color: #fff;
  letter-spacing: .25px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  font-family: Inconsolata, monospace;
  font-size: 25px;
  line-height: 20px;
  text-decoration: none;
  transition: none;
  overflow: visible;
}

.nav-link-copy:hover {
  color: rgba(26, 27, 31, .75);
}

.nav-link-copy:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-link-copy[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-link-copy {
  color: #fff;
  letter-spacing: .25px;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  font-family: Inconsolata, monospace;
  font-size: 25px;
  line-height: 25px;
  transition: none;
  display: inline-block;
  position: absolute;
  top: 13%;
  bottom: auto;
  left: -100%;
  right: auto;
  overflow: visible;
}

.nav-link-copy:hover {
  color: rgba(26, 27, 31, .75);
}

.nav-link-copy:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-link-copy[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.lynx-nav-button {
  color: #000;
  text-align: center;
  letter-spacing: 1.3px;
  text-transform: none;
  -webkit-text-fill-color: inherit;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 50px;
  padding: 15px 25px;
  font-family: Varela, sans-serif;
  font-size: 18px;
  line-height: 120%;
  transition-property: background-color;
}

.lynx-nav-button:hover {
  background-color: #19c1c8;
}

.lynx-nav-buttons {
  min-width: 250px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
  margin-left: 14px;
  display: flex;
}

.lynx-nav {
  justify-content: center;
  align-items: center;
  display: flex;
}

.lynx-nav-link {
  color: #fff;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  padding: 15px 20px;
  font-family: Varelaround, sans-serif;
  font-size: 18px;
  line-height: 140%;
  transition: color .2s;
}

.lynx-nav-link:hover {
  color: #19c1c8;
}

.lynx-nav-link.w--current {
  color: #fff;
  font-family: Varelaround, sans-serif;
}

.lynx-nav-link.w--current:hover {
  color: #19c1c8;
  font-size: 18px;
}

.lynx-nav-menu {
  flex: 0 auto;
}

.lynx-logo {
  width: 140px;
}

.lynx-brand {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  padding: 0;
  display: flex;
}

.lynx-nav-block {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.lynx-navbar {
  -webkit-text-fill-color: inherit;
  background-color: #272727;
  background-clip: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 20px;
  display: flex;
}

.body {
  background-color: #dedede;
}

.lynx-nav-buttons-copy {
  min-width: 250px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  min-width: 250px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
  margin-left: 14px;
  display: none;
}

.body-2 {
  background-color: #dedede;
}

.div-block {
  position: relative;
  max-width: 1392px;
  min-height: 800px;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Stellt das Bild hinter den Inhalt */
  border-radius: inherit; 
}

.team-member-image {
  width: 200px;
  height: 200px;
  object-fit: cover; /* stellt sicher, dass das Bild den Container bedeckt */
  border-radius: 10px; /* abgerundete Ecken wie bei deinem Container */
}

.section-5 {
  margin-top: 60px;
  padding-left: 10px;
  padding-right: 10px;
}

.list-2 {
  list-style-type: none;
  max-width: 800px;
  margin: auto;
  padding-left: 0;
}

.div-block-2, .div-block-3 {

  min-height: 200px;
  background-color: #dedede;
  border-radius: 20px;
}

.heading {
  color: #fff;
  text-align: center;
  font-family: Varelaround, sans-serif;
  font-size: 50px;
  font-weight: 700;
}

.div-block-4 {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 27px;
  display: block;
}

.div-block-5 {
  width: 200px;
  height: 200px;

  background-position: 0 0;
  background-size: cover;
  border-radius: 10px;
}

.text-block-7 {
  font-family: Varelaround, sans-serif;
  font-size: 40px;
  font-weight: 700;
}

.quick-stack {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.button-2 {
  margin-top: 5px;
  padding: 5px 10px;
  background-color: #19c1c8;
  border-radius: 40px;
  font-family: Varelaround, sans-serif;
  color: #ffffff;
  font-weight: 500;
  text-decoration: none;
}

.list-item-2, .list-item-3 {
  
  margin-bottom: 5px;
}

.list-3 {

  padding-left: 20px;
}

.text-block-8, .text-block-9, .text-block-10 {
  font-family: Varelaround, sans-serif;
  font-size: 20px;
}

.list-3 div {
  font-size: 20px; /* Beispiel für die Schriftgröße */
  font-family: Varelaround, sans-serif; /* Beispiel für fette Schrift */
  color: #000; /* Beispiel für die Schriftfarbe */
}

.text-block-11 {
  font-family: Varelaround, sans-serif;
  font-size: 22px;
}

.div-block-5-copy {
  width: 200px;
  height: 200px;

  background-position: 0 0;
  background-size: cover;
  border-radius: 10px;
}

.list-item-4 {
  margin-bottom: 5px;
}

.text-block-12 {
  font-family: Varelaround, sans-serif;
  font-size: 20px;
}

.body-3 {
  background-color: #dedede;
}

.divider-2 {
  width: 100%;
  height: 2px;
  opacity: .15;
  background-color: #161616;
  margin-top: 20px;
  margin-bottom: 20px;
}

.paragraph {
  margin-bottom: 0;
}

.paragraph.top-margin-small {
  margin-top: auto;
  padding-left: 74px;
  font-family: Varelaround, sans-serif;
}

.contact-link {
  width: 100%;
  color: #161616;
  align-items: flex-start;
  margin-top: 10px;
  font-weight: 400;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.contact-link:hover {
  color: #19c1c8;
}

.flag-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.additional-info-item {
  align-items: flex-start;
  margin-top: 20px;
  display: flex;
}

.additional-info-item.smaller-margin {
  margin-top: 10px;
}

.name-text {
  text-align: center;
  letter-spacing: -.5px;
  font-family: Varelaround, sans-serif;
  font-size: 21px;
  font-weight: 700;
}

.profile-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 4px solid #fff;
  border-radius: 100px;
  margin-bottom: 25px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, .07);
}

.skill-level-animation {
  width: 60px;
  height: 60px;
  transform-style: preserve-3d;
  transform: rotateX(0)rotateY(180deg)rotateZ(0);
}

.contact-info-wrap {
  width: 300px;
  background-color: #fff;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 80px;
  padding: 35px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 10vh;
  box-shadow: 1px 1px 60px rgba(0, 0, 0, .06);
}

.contact-info-wrap.after-sections {
  display: none;
}

.section-icon-box {
  width: 44px;
  height: 44px;
  background-color: #19c1c8;
  border-radius: 10px;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  display: flex;
}

.h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Varelaround, sans-serif;
}

.section-content-wrap {
  width: 100%;
  padding-left: 64px;
}

.section-content-wrap.top-margin-small {
  margin-top: 15px;
}

.section-content-wrap.top-margin-normal {
  width: 100%;
  margin-top: 50px;
  padding-left: 0;
}

.button-icon {
  width: 23px;
  height: 23px;
  background-color: #19c1c8;
  border-radius: 5px;
  margin-right: 10px;
  padding: 4px;
}

.section-name-wrap {
  align-items: center;
  display: flex;
}

.small-boxes-grid {
  grid-column-gap: 24px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.additional-headline-info {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 400;
}

.download-cv-button {
  width: 100%;
  color: #161616;
  letter-spacing: -.3px;
  text-transform: uppercase;
  border: 3px solid rgba(133, 133, 133, .2);
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2em;
  transition: all .2s;
  display: flex;
}

.download-cv-button:hover {
  border-color: #19c1c8;
}

.text-link {
  transition: all .2s;
}

.text-link:hover {
  opacity: .7;
  color: #ff6b00;
}

.stars-icons-wrap {
  justify-content: center;
  align-items: flex-start;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
}

.info-message-wrap {
  letter-spacing: -.3px;
  align-items: center;
  margin-top: 15px;
  font-weight: 600;
  display: flex;
}

.h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Varelaround, sans-serif;
}

.h4.gray {
  color: #858585;
}

.content-container {
  width: 100%;
  max-width: 1140px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.section-6 {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 8%;
  padding-right: 8%;
  display: flex;
}

.section-6.main {
  height: 100%;
  background-color: #fbfbfb;
  padding-top: 120px;
  padding-bottom: 120px;
}

.buttons-wrap {
  width: 100%;
}

.change-language-button {
  width: 100%;
  color: #161616;
  letter-spacing: -.3px;
  text-transform: uppercase;
  background-color: rgba(22, 22, 22, .05);
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2em;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.change-language-button:hover {
  background-color: rgba(22, 22, 22, .1);
}

.contact-links-wrap {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
}

.button-content-wrap {
  align-items: center;
  display: flex;
}

.bottom-elements-wrap {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.percentage-text {
  font-weight: 700;
  position: absolute;
}

.additional-info-icon {
  margin-top: 5px;
  margin-right: 10px;
}

.under-picture-info {
  width: 100%;
}

.content-box-item {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.age-text {
  color: #858585;
  text-align: center;
  letter-spacing: -.5px;
  margin-top: 5px;
  font-family: Varelaround, sans-serif;
  font-size: 21px;
  font-weight: 700;
}

.top-element-wrap {
  width: 60px;
  height: 60px;
  border-radius: 14px;
  margin-bottom: 20px;
  overflow: hidden;
}

.top-element-wrap.hobby {
  background-color: rgba(22, 22, 22, .05);
  justify-content: center;
  align-items: center;
  display: flex;
}

.top-element-wrap.skills {
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 0 4px rgba(22, 22, 22, .05);
}

.additional-info-text {
  color: #858585;
  line-height: 1.6em;
}

.additional-info-text.made-by {
  text-align: right;
}

.h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.section-wrap {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 55px;
  display: flex;
}

.section-wrap.last-section {
  margin-bottom: 0;
}

.contact-icon {
  background-color: #19c1c8;
  border-radius: 6px;
  margin-top: 3px;
  margin-right: 10px;
  padding: 3px;
}

.star-icon {
  margin-left: 2px;
  margin-right: 2px;
}

.main-info-strip-wrap {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  display: flex;
}

.double-info-grid {
  width: 100%;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.div-block-6 {
  min-height: 70px;
  min-width: 250px;
  background-color: #fff;
  border-radius: 40px;
}

.div-block-6:hover {
  background-color: #19c1c8;
}

.text-block-15 {
  text-align: center;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  font-family: Varela, sans-serif;
  font-size: 18px;
}

.text-block-14 {
  text-align: center;
  font-family: Varela, sans-serif;
  font-size: 18px;
}

.link {
  font-family: Varelaround, sans-serif;
  text-decoration: none;
}

.link:hover {
  font-size: 20px;
}

.link-2 {
  font-family: Varelaround, sans-serif;
  text-decoration: none;
}

.link-2:hover {
  font-size: 20px;
}

.lite5 {
  padding-left: 88px;
}

.body-4 {
  background-color: #dedede;
}

.list-4 {
  padding-left: 98px;
}

.lynx-text-check {
  color: #797c83;
  font-family: Varela, sans-serif;
  font-size: 18px;
  line-height: 140%;
}

.lynx-icon-check {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.lynx-check {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 14px;
  display: flex;
}

.lynx-heading-2 {
  max-width: 740px;
  color: #1f2022;
  margin-top: 0;
  margin-bottom: 36px;
  font-family: PT Serif, serif;
  font-size: 46px;
  font-weight: 400;
  line-height: 120%;
}

.lynx-subtitle-2 {
  color: #1f2022;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  font-family: Varela, sans-serif;
  font-size: 12px;
  line-height: 140%;
}

.lynx-block-right {
  padding-left: 10%;
}

.lynx-image-2 {
  min-height: 500px;
  object-fit: cover;
  border-radius: 30px;
  box-shadow: 0 60px 60px -15px rgba(31, 32, 34, .1);
}

.lynx-paragraph-features {
  max-width: 294px;
  color: #797c83;
  margin-bottom: 0;
  font-family: Varela, sans-serif;
  font-size: 16px;
  line-height: 165%;
}

.lynx-icon-heading {
  color: #1f2022;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Varela, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 132%;
}

.lynx-icon {
  width: 28px;
  height: 28px;
  margin-right: 15px;
}

.lynx-block-icon-heading {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.lynx-block-absolute {
  z-index: 50;
  background-color: #fff;
  border-radius: 30px;
  padding: 30px 35px;
  position: absolute;
  top: auto;
  bottom: -13%;
  left: -4%;
  right: auto;
  box-shadow: 0 60px 60px -15px rgba(31, 32, 34, .1);
}

.lynx-block-image-2 {
  max-width: 500px;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  display: flex;
  position: relative;
}

.lynx-grid-image-left {
  width: 100%;
  grid-column-gap: 26px;
  grid-row-gap: 26px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1.2fr;
  align-items: center;
}

.lynx-content-2 {
  width: 100%;
  max-width: 1080px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  display: none;
}

.f-accordian-icon {
  width: 24px;
  height: 24px;
  color: #6b7094;
  justify-content: center;
  align-items: center;
  display: flex;
}

.f-accordian-wrapper {
  max-width: 500px;
  grid-row-gap: 24px;
  background-color: #a093c2;
  border: 1px solid #e4e6f1;
  border-radius: 8px;
  flex-direction: column;
  padding: 24px;
  display: flex;
}

.f-paragraph-small {
  color: #000;
  letter-spacing: -.01em;
  margin-bottom: 0;
  font-family: Varelaround, sans-serif;
  font-size: 20px;
  line-height: 1.8;
}

.f-accordian-title {
  color: #000;
  letter-spacing: -.01em;
  font-family: Varelaround, sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
}

.heading-14 {
  text-align: center;
  font-family: Varelaround, sans-serif;
  display: block;
}

.f-accordian-wrapper-copy {
  width: 100%;
  max-width: 900px;
  grid-row-gap: 24px;
  border: 3px solid #19c1c8;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 19px;
  padding: 24px;
  display: flex;
}

.grid-7 {
  grid-template-rows: auto;
  align-items: center;
  justify-items: center;
}

.f-accordian-toggle {
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4px;
  padding: 0;
}

.f-accordian-list {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: block;
  position: static;
  overflow: hidden;
}

.f-accordian-dropdown {
  width: 98%;
  margin-left: 0;
  margin-right: 0;
  transition: all .3s;
  display: block;
}

.text-block-107 {
  font-family: Varelaround, sans-serif;
  font-size: 20px;
  display: inline;
}

.heading-13 {
  color: #000;
  font-family: Varelaround, sans-serif;
  display: block;
}

.f-accordian-content {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4px;
  padding-bottom: 4px;
}

.section-8 {
  max-width: 1440px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  margin-top: 67px;
  margin-bottom: 86px;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
}

.f-accordian-title-wrapper {
  width: 100%;
  white-space: normal;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body-5 {
  background-color: #dedede;
}

.section-9 {
  min-height: 100px;
}

.text-block-108, .text-block-109, .text-block-110, .text-block-111, .text-block-112, .text-block-113, .text-block-114, .text-block-115, .text-block-116, .text-block-117, .text-block-118, .text-block-119, .text-block-120, .text-block-121, .text-block-122, .text-block-123, .text-block-124, .text-block-125, .text-block-126, .text-block-127, .text-block-128, .text-block-129, .text-block-130, .text-block-131, .text-block-132, .text-block-133, .text-block-134, .text-block-135, .text-block-136, .text-block-137, .text-block-138, .text-block-139, .text-block-140 {
  font-family: Varelaround, sans-serif;
}

@media screen and (min-width: 1280px) {


  .heading-14 {
    color: #000;
  }

  .div-block-49 {
    color: #e7f1f2;
  }

  .text-block-107, .heading-13 {
    color: #000;
  }
}

@media screen and (min-width: 1440px) {
  .section-8 {
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .section.cc-cta {
    padding-left: 0;
    padding-right: 0;
  }

  .styleguide-block {
    text-align: center;
  }

  .button {
    justify-content: center;
  }

  .button.cc-contact-us {
    display: none;
  }

  .heading-jumbo {
    font-size: 56px;
    line-height: 70px;
  }

  .logo-link.w--current {
    flex: 1;
  }

  .menu-icon {
    display: block;
  }

  .menu {
    margin-left: 30px;
    position: static;
  }

  .navigation-wrap {
    background-color: #fff;
  }

  .navigation {
    padding: 25px 30px;
  }

  .navigation-item {
    text-align: center;
    padding: 15px 30px;
    transition: background-color .4s, opacity .4s, color .4s;
  }

  .navigation-item:hover {
    background-color: #f7f8f9;
  }

  .navigation-item:active {
    background-color: #eef0f3;
  }

  .menu-button {
    padding: 0;
  }

  .menu-button.w--open {
    background-color: rgba(0, 0, 0, 0);
  }

  .navigation-items {
    background-color: #fff;
  }

  .cta-text {
    width: auto;
  }

  .cta-wrap {
    width: auto;
    padding: 80px 50px 90px;
  }

  .our-services-grid {
    width: 80%;
    text-align: center;
    grid-template: ". ."
                   ". ."
                   ". ."
                   / 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .service-icon {
    display: inline-block;
  }

  .contact-form-grid {
    width: 80%;
    grid-row-gap: 50px;
    text-align: center;
    grid-template: "."
                   "."
                   / 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .get-in-touch-form {
    text-align: left;
  }

  .our-contacts {
    width: 80%;
    grid-template: "."
                   "."
                   "."
                   / 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .team-members {
    grid-template: ". ."
                   ". ."
                   ". ."
                   / 1fr 1fr;
  }

  .team-member-title-wrap {
    margin-bottom: 30px;
  }

  .project-details-grid {
    width: 80%;
    grid-row-gap: 50px;
    text-align: center;
    grid-template: "."
                   "."
                   / 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .blog-detail-header-wrap {
    width: 70%;
  }

  .detail-header-image {
    height: 460px;
  }

  .blog-item {
    width: 80%;
  }

  .blog-preview-image {
    height: 460px;
  }

  .blog-summary-wrap {
    width: 100%;
  }

  .about-grid {
    grid-row-gap: 50px;
    text-align: center;
    grid-template: "."
                   "."
                   / 1fr;
  }

  .about-grid.cc-about-2 {
    grid-template-columns: 1fr;
    grid-template-areas: "."
                         ".";
  }

  .intro-text {
    width: auto;
  }

  .collection-wrap {
    flex-flow: column;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }

  .blog-preview-wrap {
    width: 80%;
    text-align: center;
    padding-right: 15px;
  }

  .nav-menu-wrapper {
    background-color: rgba(0, 0, 0, 0);
  }

  .nav-menu {
    background-color: #fff;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .nav-link {
    padding-left: 5px;
    padding-right: 5px;
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: absolute;
  }

  .nav-button-wrapper {
    width: 100%;
    margin-left: 0;
  }

  .menu-button-2 {
    padding: 12px;
  }

  .menu-button-2.w--open {
    color: #fff;
    background-color: #a6b1bf;
  }

  .lynx-heading-white {
    font-size: 48px;
  }

  .lynx-block-hero-large {
    width: 60%;
    border-bottom-right-radius: 260px;
    margin-left: -12px;
    padding: 70px 40px;
  }

  .lynx-heading {
    font-size: 38px;
  }

  .lynx-grid-image-right, .lynx-grid-image-right-copy {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .nav-link-copy {
    padding-left: 5px;
    padding-right: 5px;
  }

  .lynx-menu-button {
    color: #1f2022;
    background-color: #fff;
    border-radius: 50%;
    margin-left: 8px;
    padding: 13px;
    font-size: 20px;
    transition: background-color .2s, color .2s;
    box-shadow: 0 10px 10px -10px rgba(31, 32, 34, .2);
  }

  .lynx-menu-button.w--open {
    color: #fff;
    background-color: #1f2022;
  }

  .lynx-nav-link {
    text-align: center;
  }

  .lynx-nav-menu {
    text-align: center;
    background-color: #272727;
    border-bottom-left-radius: 30px;
    flex-direction: row;
    flex: 0 auto;
    justify-content: center;
    align-items: center;
    margin-left: 50%;
    padding: 10px;
    display: flex;
    box-shadow: 0 10px 15px -10px rgba(31, 32, 34, .1);
  }

  .lynx-nav-buttons-copy {
    display: none;
  }

  .profile-image {
    width: 40%;
    height: auto;
    border-radius: 9999px;
    margin-bottom: 0;
  }

  .contact-info-wrap {
    width: 100%;
    flex-direction: row;
    order: -1;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
    margin-left: 0;
    padding: 50px;
    position: static;
  }

  .contact-info-wrap.after-sections {
    order: 0;
    display: flex;
  }

  .content-container {
    flex-direction: column;
  }

  .section-6.main {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .under-picture-info {
    width: 50%;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
  }

  .section-wrap {
    margin-bottom: 100px;
  }

  .main-info-strip-wrap {
    width: 100%;
  }

  .lynx-heading-2 {
    font-size: 38px;
  }

  .lynx-icon-heading {
    font-size: 19px;
  }

  .lynx-grid-image-left {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .f-accordian-list {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .section {
    margin-left: 15px;
    margin-right: 15px;
  }

  .section.cc-cta {
    padding: 15px;
  }

  .section.cc-home-wrap, .section.cc-store-home-wrap {
    margin-left: 15px;
    margin-right: 15px;
  }

  .container {
    text-align: center;
  }

  .paragraph-bigger {
    font-size: 16px;
    line-height: 28px;
  }

  .rich-text {
    width: 90%;
    max-width: 470px;
    text-align: left;
  }

  .heading-jumbo {
    font-size: 50px;
    line-height: 64px;
  }

  .heading-jumbo-small {
    font-size: 30px;
    line-height: 52px;
  }

  .logo-link {
    padding-left: 0;
  }

  .navigation {
    padding: 20px 30px;
  }

  .cta-wrap {
    padding-left: 30px;
    padding-right: 30px;
  }

  .intro-content {
    width: 80%;
  }

  .our-services-grid {
    grid-row-gap: 60px;
    grid-template: "."
                   "."
                   "."
                   "."
                   "."
                   "."
                   / 1fr;
  }

  .status-message.cc-no-data {
    width: 80%;
    max-width: 470px;
  }

  .contact-form-wrap {
    padding: 30px;
  }

  .text-field.cc-contact-field, .text-field.cc-textarea {
    text-align: left;
  }

  .team-pic {
    height: 300px;
    margin-bottom: 30px;
  }

  .team-member-name {
    font-size: 16px;
    line-height: 28px;
  }

  .project-name-wrap {
    margin-bottom: 30px;
  }

  .project-name-link {
    font-size: 16px;
    line-height: 28px;
  }

  .detail-image {
    margin-bottom: 15px;
  }

  .blog-detail-header-wrap {
    width: 90%;
    max-width: 470px;
    text-align: left;
  }

  .blog-item {
    width: 90%;
    max-width: 470px;
  }

  .blog-summary-wrap {
    text-align: center;
  }

  .utility-page-wrap {
    padding: 15px;
  }

  ._404-wrap {
    padding: 30px;
  }

  .navbar-brand {
    padding-left: 0;
  }

  .nav-menu {
    flex-direction: column;
    padding-bottom: 30px;
    padding-left: 0;
  }

  .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .nav-dropdown {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .nav-dropdown-toggle {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .nav-dropdown-list.shadow-three {
    box-shadow: 0 8px 50px rgba(0, 0, 0, .05);
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: relative;
  }

  .nav-dropdown-list.shadow-three.mobile-shadow-hide {
    box-shadow: none;
  }

  .mobile-margin-top-10 {
    margin-top: 10px;
  }

  .lynx-heading-white {
    font-size: 40px;
  }

  .lynx-block-hero-large {
    width: 80%;
    margin-bottom: -30px;
  }

  .lynx-hero-large {
    justify-content: flex-end;
    align-items: flex-start;
  }

  .lynx-image-absolute {
    left: -2%;
  }

  .lynx-heading {
    max-width: 540px;
    font-size: 34px;
  }

  .lynx-block-left {
    padding-left: 5%;
    padding-right: 5%;
  }

  .lynx-grid-image-right {
    grid-row-gap: 60px;
    grid-template-columns: 1fr;
  }

  .section-3 {
    display: none;
  }

  .lynx-grid-image-right-copy {
    grid-row-gap: 60px;
    grid-template-columns: 1fr;
  }

  .nav-link-copy {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .lynx-nav-buttons {
    display: none;
  }

  .lynx-nav-link {
    text-align: center;
  }

  .lynx-nav-menu {
    border-radius: 0 0 0 40px;
    flex-direction: row;
    flex: 0 auto;
    justify-content: center;
    align-items: center;
    margin-left: 34%;
    display: flex;
  }

  .lynx-nav-buttons-copy {
    margin-top: 10px;
    display: block;
  }

  .div-block {
    min-height: 850px;
  }

  .text-block-7 {
    line-height: 35px;
  }

  .profile-image {
    width: 180px;
    margin-bottom: 20px;
  }

  .contact-info-wrap {
    flex-direction: column;
  }

  .section-content-wrap {
    padding-left: 0;
  }

  .section-content-wrap.top-margin-small {
    margin-top: 30px;
  }

  .small-boxes-grid {
    grid-row-gap: 50px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .section-6.main {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .under-picture-info {
    width: 100%;
    align-items: center;
  }

  .div-block-6 {
    padding-top: 2px;
  }

  .lynx-heading-2 {
    max-width: 540px;
    font-size: 34px;
  }

  .lynx-block-right {
    padding-left: 5%;
    padding-right: 5%;
  }

  .lynx-paragraph-features, .lynx-icon-heading {
    font-family: Varelaround, sans-serif;
  }

  .lynx-block-absolute {
    bottom: -23%;
    left: -2%;
  }

  .lynx-grid-image-left {
    grid-row-gap: 60px;
    grid-template-columns: 1fr;
  }

  .lynx-content-2 {
    margin-top: 106px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .section-7 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 36px;
    line-height: 52px;
  }

  .rich-text {
    width: 100%;
    max-width: none;
  }

  .heading-jumbo {
    font-size: 36px;
    line-height: 48px;
  }

  .menu {
    margin-left: 15px;
  }

  .navigation {
    padding-left: 20px;
    padding-right: 20px;
  }

  .menu-button, .menu-button.w--open {
    flex: none;
  }

  .cta-wrap {
    padding-left: 15px;
    padding-right: 15px;
  }

  .status-message.cc-no-data, .contact-form-grid {
    width: 100%;
  }

  .our-contacts {
    width: 90%;
  }

  .team-members {
    grid-template: "."
                   "."
                   "."
                   "."
                   "."
                   "."
                   / 1fr;
  }

  .project-details-grid {
    width: 100%;
  }

  .blog-detail-header-wrap, .blog-item {
    width: 100%;
    max-width: none;
  }

  .nav-menu {
    flex-direction: column;
  }

  .nav-button-wrapper {
    width: auto;
  }

  .lynx-heading-white {
    font-size: 34px;
  }

  .lynx-block-hero-large {
    width: 100%;
    border-bottom-right-radius: 140px;
    margin-bottom: -400px;
    margin-left: 0;
    padding: 50px 30px;
  }

  .lynx-hero-large {
    min-height: 400px;
    margin-bottom: 400px;
  }

  .lynx-heading {
    font-size: 29px;
  }

  .lynx-nav-buttons {
    display: none;
  }

  .lynx-nav-menu {
    margin-left: 0%;
  }

  .lynx-nav-buttons-copy {
    display: block;
  }

  .div-block {
    min-height: 900px;
  }

  .div-block-5 {
    width: 120px;
    height: 120px;
  }

  .text-block-7 {
    font-size: 30px;
  }

  .text-block-8, .text-block-9, .text-block-10 {
    font-size: 16px;
  }

  .text-block-11 {
    font-size: 18px;
  }

  .div-block-5-copy {
    width: 120px;
    height: 120px;
  }

  .text-block-12 {
    font-size: 16px;
  }

  .profile-image {
    width: 150px;
  }

  .contact-info-wrap {
    padding: 35px;
  }

  .section-content-wrap.top-margin-normal {
    margin-top: 40px;
  }

  .section-name-wrap {
    align-items: center;
  }

  .small-boxes-grid {
    grid-template-columns: 1fr 1fr;
  }

  .additional-info-text.made-by {
    text-align: left;
    margin-top: 20px;
  }

  .double-info-grid {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
  }

  .lynx-heading-2 {
    font-size: 29px;
  }

  .lynx-icon-heading {
    font-size: 18px;
  }

  .lynx-block-absolute {
    bottom: -24%;
  }

  .lynx-content-2 {
    margin-bottom: 200px;
  }

  .section-7 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .heading-14 {
    font-size: 25px;
  }

  .f-accordian-wrapper-copy {
    max-width: 320px;
  }

  .grid-7 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-direction: column;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: flex;
  }

  .f-accordian-dropdown {
    width: 280px;
  }
}

#w-node-_67029e0f-f2ce-8fe4-4d8b-28cc52c4aa3e-a5962bd3, #w-node-_67029e0f-f2ce-8fe4-4d8b-28cc52c4aa47-a5962bd3, #w-node-_496e89b3-7698-8812-7fdf-f05c2de45762-a5962bd3, #w-node-_496e89b3-7698-8812-7fdf-f05c2de45759-a5962bd3, #w-node-_41f6721d-42c6-8b0d-a6ff-b75a42038aa2-a5962bd3, #w-node-_41f6721d-42c6-8b0d-a6ff-b75a42038aab-a5962bd3, #w-node-_6c107fa3-f7a4-3a3d-545b-519f9182d05e-a5962bd3, #w-node-ffe13eb7-f720-93a8-bd62-175dad67c6eb-a5962bd3, #w-node-_8353ae5e-2c33-e128-d8f3-1839355df5aa-a5962bd3 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c0b2f7d7-0395-da82-4c3d-6b2449523fc9-a5962bdf {
  grid-template-rows: auto 123px 41px 42px;
  grid-template-columns: 1fr 2.25fr;
}

#w-node-_7a4be3a3-9b22-b507-76c5-a1866269fea2-a5962bdf {
  grid-area: span 4 / span 1 / span 4 / span 1;
}

#w-node-_9ad46468-c50f-922e-ceb5-4c2100940847-a5962bdf, #w-node-_43e76db2-5bde-7413-54ee-f2a0705f5342-a5962bdf, #w-node-_82bf2a59-c767-d018-52fe-0c9b39a6f27c-a5962bdf, #w-node-_1bd59189-8051-ba6c-d2d9-a36bf3c03fa1-a5962bdf {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_54eb374d-d78a-f826-47cd-bbb905358aac-a5962bdf {
  grid-template-rows: auto 150px 41px 42px;
  grid-template-columns: 1fr 2.25fr;
}

#w-node-_54eb374d-d78a-f826-47cd-bbb905358aad-a5962bdf {
  grid-area: span 4 / span 1 / span 4 / span 1;
}

#w-node-_54eb374d-d78a-f826-47cd-bbb905358aaf-a5962bdf, #w-node-_54eb374d-d78a-f826-47cd-bbb905358ab2-a5962bdf, #w-node-_54eb374d-d78a-f826-47cd-bbb905358abd-a5962bdf, #w-node-_54eb374d-d78a-f826-47cd-bbb905358ac0-a5962bdf, #w-node-b1fcd423-2f6e-de0e-7963-d95da66121b6-1ec6476b, #w-node-b1fcd423-2f6e-de0e-7963-d95da66121b9-1ec6476b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (max-width: 767px) {
  #w-node-c0b2f7d7-0395-da82-4c3d-6b2449523fc9-a5962bdf {
    grid-template-rows: auto 123px 66px 42px;
    grid-template-columns: 1fr 2.25fr;
  }

  #w-node-_54eb374d-d78a-f826-47cd-bbb905358aac-a5962bdf {
    grid-template-rows: auto 150px 59px 42px;
    grid-template-columns: 1fr 2.25fr;
  }
}


